<template>
  <div>


<!-- Modal -->
<div class="modal  fade"  id="PlanModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="Planmodal">
  <div class="modal-dialog modal-xl ">
    <div class="modal-content " >
      <div class="container p-2 pb-4" >
          <div class="row ">
          <div class="d-flex col-12 justify-content-end">
               <button
                   @click="closeModal"
          class="btn"
          style=""><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
          </div>
          <div class="row px-5 " >
              <div class="col-12 ">
                  <p class="TitleHead">Subscription plans</p>
              </div>

          </div>

        <div class="row">
            <div class="col-12" v-if="IsShopifyUser">
                <ShopifyPlans/>
            </div>
            <!-- <div class="col-12" v-else>
                <WebUserPlans/>
            </div> -->
        </div>
       </div>

    </div>
  </div>
</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShopifyPlans from "./ShopifyPlans.vue"
// import WebUserPlans from "./WebUserPlans.vue"

import $ from 'jquery'
export default {
    components:{
ShopifyPlans,
// WebUserPlans
    },
data(){
    return{
        plans:[]
    }
},
mounted(){
},
    methods:{
        OpenModal(){

        $('#PlanModal').modal('show')
    },
        closeModal(){
            $('#PlanModal').modal('hide')
        },
    },
    computed:{
         ...mapGetters( ["getIsShopifyUser"]),
         IsShopifyUser(){
    return this.getIsShopifyUser
   }
    }
}
</script>

<style scoped>
/* .UpgradeBtn{
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      background: transparent;
      border-radius: 6px; text-decoration:none;color:#ffffff
}
.UpgradeBtn:hover{
    background: #ffffff;
    color:#ffa201;
} */
.planModalStyle{
    max-height:80vh;
    overflow: hidden scroll;
    scroll-behavior: smooth;
}
.modal-content
{
    border-radius: 20px;
        overflow: hidden;
}
.modal-btn{
    background:#ffa201;
    color:#ffffff;
    border: none;
}
.modal-btn:hover{
    color:#ffffff;
}

.btn-profile-seeting
{
    border: 1px solid #ffa201;
    color: #ffa201;
}
.btn-profile-seeting:hover
{
    border: 1px solid #ffa201;
    color: #ffffff;
    background:#FFA201;
}


.TitleHead{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;

color: #18191C;
}

/* 
.topSection{
    color:#ffffff;
     font-family: 'Poppins';
}
.topSection .title p{
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;
}

 .priceSection .price{
font-style: normal;
font-weight: 300;
font-size: 36px;
line-height: 54px;
}
 .priceSection .subText{
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
}

.priceText span{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}

.buttonSection a{
    background:transparent;
    border: 1.02182px solid #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
border-radius: 25px;
font-size:14px;
font-weight:500;
color:#ffffff;
}
.buttonSection a:hover{
    background:#ffffff;
color:#042542;
}

.midSection{
    font-family: 'Poppins';
    color: #535355;
}
.midTitle{
    font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
}

.midFeatures p{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}
.midFeatures p span{
font-weight: 600;
}
.midCheckFeatures p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

color: #535355;
}

.endSection p{
    color: #535355;
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
} */
</style>
