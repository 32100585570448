<template>
  <div class="">
    <Header class="pb-4" heading="Integrations" />

    <div class="card border-radius" style="position: relative">
     
     
      <div class="card-body">
        <div class="mb-4">
          <div class="d-flex flex-wrap">
            <button
              class="btn categoryBtns"
              v-for="category in categoryList"
              :key="category.id"
              :class="selectedCategoryId == category.id ? 'selected' : null"
              @click="updateSelectedCategoryId(category.id)"
            >
              {{ category.title }}
            </button>
          </div>
        </div>
        <div v-if="isIntegrationLoading">
                <div class="row">
                  <div
                    class="col-6 col-lg-4 col-xl-4 mb-3"
                    v-for="index in 3"
                    :key="index">
                    <AllIntegrationSkeleton />
                  </div>
                </div>
              </div>
              <div class="" v-else>
                <div class="row mb-3">
                    <div class="col-12 col-md-6">
                      <FilterBox
                        :search="true"
                        class="px-2"
                        v-model.trim="searchText"
                        style="
                          padding-bottom: 0.4rem !important;
                          padding-top: 0.4rem !important;
                        "
                        placeholder="Search Integration..."
                      ></FilterBox>
                    </div>
                    </div>
              
        <div  class="row InstalledIntegration-main">
         
              <div
               data-aos="fade-up"
                class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                v-for="(integration, index) in sortIntegration"
                :key="index"
                style="cursor: pointer"
              >
                <div
                  class=""
                  style="
                    border-radius: 16px;
                    border: 1px solid rgba(237, 237, 237, 1);
                    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
                  "
                >
                  <div class="card-section p-4" style="min-height: 220px;">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center" style="gap: 20px">
                        <div
                          class="img"
                          style="width: 48px; height: 48px; border-radius: 14px"
                        >
                          <LazyImage
                            :src="`${integration.image}`"
                            :key="integration.image"
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 14px;
                            "
                            :alt="integration.title"
                            :title="integration.title"
                            class="rounded"
                          />
                        </div>
                        <div class="">

                          <div class="integration-heading text-left">
                            <p class="m-0">{{ integration.title }}</p>
                          </div>
                          <div class="planDiv">
                              <p v-if=" (integration.installed != 'no' && integration.status) || integration.handle_key ==getUserType"
                                class="m-0 planText"
                                :style="getPlanStyles(integration.plan_type_id)">
                                Installed
                              </p>
                              <p v-else
                                class="m-0 planText"
                                :style="getPlanStyles(integration.plan_type_id)">
                                {{ getPlanText(integration.plan_type_id) }}
                              </p>
                            </div>
                        </div>
                      </div>
                      <div
                            class="actionDiv d-flex align-items-center"
                            v-if="
                            integration.installed != 'no' && integration.status&&
                              !nonAuthIntegrations.includes(
                                integration.handle_key
                              )
                            ">
                            <div>
                              <button
                                class="btn"
                                @click.stop="
                                  RemoveIntegration(integration.connection_id)
                                ">
                                <svg
                                  width="16"
                                  height="18"
                                  viewBox="0 0 16 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 0V1H0V3H1V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V3H16V1H11V0H5ZM3 3H13V16H3V3ZM5 5V14H7V5H5ZM9 5V14H11V5H9Z"
                                    fill="#7C8290" />
                                </svg>
                              </button>
                            </div>
                          </div>
                    </div>
                    <div class="integration-description mt-3 text-left">
                      <p class="m-0">{{ integration.short_description }}</p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-end px-4 py-3"
                    style="border-top: 1px solid rgba(237, 237, 237, 1)"
                  >
                    <div class="" >
                      <button class="view-btn"  @click.stop="openIntegration(integration.id)">View integration</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       </div>
      </div>
     
        <delete-modal id="deleteIntegration" ref="deleteIntegration" @click="deleteIntegration" :isLoading="isDeleteLoading" title="Delete Integration" item="integration" ></delete-modal>
      <UpgradePlanModal
        ref="UpgradeUserPlanModalIntegration"
        :title="upgradeModalTitle"
        :subText="upgradeModalsubText"
        @openPlansModalChild="openShopifyPlansModal" />
      <ShopifyPlansModal ref="PlansModalRefIntegration" />
    </div>
  </div>
</template>

<script>
import FilterBox from "../Dashboard/Layout/FilterBox.vue";
import DeleteModal from "../Dashboard/modal/DeleteModal.vue";
import axios from "axios";
import AllIntegrationSkeleton from "./AllIntegrationSkeleton.vue";
import { mapGetters } from "vuex";
import ShopifyPlansModal from "../Dashboard/modal/PlansModal.vue";
import UpgradePlanModal from "../Dashboard/modal/UpgradePlanModal.vue";
import Header from "../Dashboard/Layout/Header.vue";
import { sortBy } from "lodash";
export default {
  components: {
    AllIntegrationSkeleton,
    UpgradePlanModal,
    ShopifyPlansModal,
    Header,
    DeleteModal,
    FilterBox
  },
  data() {
    return {
      searchText:'',
      isDeleteLoading:false,
      integrationID:null,
      nonAuthIntegrations: ["shopify", "wix", "bc"],
      selectedCategoryId:'',
      categoryList: [
        { id: "", title: "All" },
        { id: 1, title: "Marketing Automation" },
        { id: 2, title: "Review Management" },
        { id: 3, title: "Payment" },
        { id: 4, title: "E-commerce" },
        { id: 6, title: "Integration Platform" },
        { id: 7, title: "Marketing & Sales" },
      ],
      searchIntegration: "",
      isIntegrationCategoryLoading: false,
      isIntegrationLoading: false,
      IntegrationCategories: [],
      AllIntegrations: [],
      selectedCategory: [],
      deleteSpinner: false,
   
      upgradeModalTitle: "Upgrade your plan to access the Integration.",
      upgradeModalsubText: `Your current Plan doesn't support the Integration. Please upgrade your account.`,
    };
  },
  watch: {
    searchIntegration(newValue, oldValue) {
      if (newValue.length == 0 && oldValue.length == 1) {
        this.getIntegrationList();
      }
    },
  },

  methods: {
    async deleteIntegration(){
       if(this.integrationID){
        try {
        this.isDeleteLoading = true
        let data = {
          connection_id: this.integrationID,
        };
        const response = await axios.post(`/integrationConnectionDisconnect`, data);
        if(response.status == 200 ){
            this.getIntegrationList()
            this.$refs.deleteIntegration.close()
             this.$toasted.show("Integration removed", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        } else{
          
              this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
       
      } catch (error) {
        console.log(error)
        if (error) {
          this.$toasted.show("Error occurred", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally{
         this.isDeleteLoading = false
      }
       } else{
       
          this.$toasted.show("Something went wrong", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        
       }
      
    },
    updateSelectedCategoryId(id) {
      this.selectedCategoryId = id;
      this.getIntegrationList();
    },
    openShopifyPlansModal() {
      this.$refs.PlansModalRefIntegration.OpenModal();
    },
    RemoveIntegration(id) {
      if (id) {
        this.integrationID=id
        this.$refs.deleteIntegration.open();
      }
    },
    clearFilter() {
      (this.selectedCategory = []), (this.searchIntegration = "");
      this.getIntegrationList();
    },
    getPlanStyles(plan) {
      if (plan || plan === 0) {
        let css;
        if (plan == 3) {
          css = `
color:var(--primary-color); font-size: 14px;`;
        }
        if (plan == 2) {
          css = `
 color:var(--primary-color); font-size: 14px;`;
        }
        if (plan == 0) {
          css = `color: rgba(102, 112, 133, 1);
    font-size: 14px;`
        }

        return css;
      }
    },
    getPlanText(plan) {
      if (plan || plan === 0) {
        let value;
        if (plan == 4) {
          value = `Pro`;
        }
        if (plan == 3) {
          value = `Plus`;
        }
        if (plan == 2) {
          value = `Lite`;
        }
        if (plan == 0) {
          value = `Free`;
        }

        return value;
      }
    },
    async getIntegrationCategories() {
      try {
        this.isIntegrationCategoryLoading = true;
        const response = await axios.get(`/getIntegrationCategories`);
        if (response.status == 200) {
          this.IntegrationCategories = response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.isIntegrationCategoryLoading = false;
      }
    },
    async getIntegrationList() {
      this.isIntegrationLoading = true;
      try {
        let data = {
          keyword: this.searchIntegration,
          categories: this.selectedCategoryId?[this.selectedCategoryId]:[],
        };
        const response = await axios.get(`/getIntegrationAppsPrivate`, {
          params: data,
        });
        if (response.status == 200) {
          this.AllIntegrations = response.data.data;
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.isIntegrationLoading = false;
      }
    },
    async openIntegration(id) {
      let isAllowed = await this.isIntegrationAllowed(id);
        if (isAllowed) {
          this.$router.push(`/integrations/${id}`);
        } else {
          this.$refs.UpgradeUserPlanModalIntegration.OpenModal();
        }
    },

    async isIntegrationAllowed(id) {
      try {
        let data = {
          app_id: id,
        };
        const response = await axios.post(`/getIntegrationAllowed`, data);
        if (
          response.data.ok &&
          response.data.message == "Integration is allowed."
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      }
    },
  },
  computed: {
    sortIntegration(){
      return [...this.getInstalledIntegration,...this.getNonInstalledIntegration].filter(e=>e.title.toLowerCase().includes(this.searchText.toLowerCase()))
    },
    getInstalledIntegration() {
      return this.AllIntegrations.filter((item) => {
        if (this.nonAuthIntegrations.includes(this.getUserType)) {
          return (
            (item.installed != "no" && item.status) ||
            item.handle_key == this.getUserType
          );
        } else {
          return item.installed != "no" && item.status;
        }
      });
    },
    getNonInstalledIntegration() {
      const integrations = this.AllIntegrations.filter((item) => {
        return (
          item.installed == "no" &&
          item.status &&
          item.handle_key !== this.getUserType
        );
      });
      return sortBy(integrations,'title')
    },
    ...mapGetters(["getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },
  },
  created() {
    this.getIntegrationCategories();
    this.getIntegrationList();
  },
};
</script>

<style>
.Integration-Main-Container {
  padding: 3% 5% 3%;
  min-height: calc(100vh - 60px);
}
#IntegrationFilterDropdown button.dropdown-toggle {
  color: #4d1b7e;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}

.filterContainer .searchInput {
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 100px;
  background: #ffffff;
}
.filterContainer .searchInput input {
  background: transparent;
  border: none;
  outline: none;
}
.filterContainer .searchInput input::placeholder {
  color: #d1d1d1;
}
.filterContainer .searchInput input:focus {
  border: none;
  outline: none;
}
.filterContainer .category-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #575768;
}
.filterContainer .category-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #575768;
  /* color: #73738d; */
}
/* .filterContainer .Category-content .category-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #575768;
} */
.filterContainer .CategoryBtn .applyBtn {
  background: #4d1b7e;
  border-radius: 100px;
  width: 100%;
  color: #ffffff;
}
.filterContainer .CategoryBtn .clearBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #73738d;
}

.right-section-main .IntegrationsHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: #292929;
}
.right-section-main .integrationDiv {
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
  border-radius: 20px;
  width: 100%;
  /* min-height:250px; */
  height: 100%;
}
.right-section-main .integrationDiv:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.right-section-main .header-section .integrationImgae {
  width: 70px;
  height: 70px;
  border-radius: 14px;
  /* min-height:70px; */
  /* height:auto; */
}
.right-section-main .header-section .planText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}
.right-section-main .mid-section .integration-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #1f1f28;
}
.right-section-main .mid-section .integration-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7c8290;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #4d1b7e !important;
  background-color: #4d1b7e !important;
}
.categoryBtns {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #73738d;
  padding-bottom: 10px;
}
.categoryBtns.selected {
  border-radius: 0 !important;
  color: #4d1b7e;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid #4d1b7e;
}
.integration-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(16, 24, 40, 1);
}
.integration-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(102, 112, 133, 1);
}
.planDiv{
  line-height:1;
}
.view-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(77, 27, 126, 1);
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
}
</style>
